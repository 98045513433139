<template lang="pug">
#release-notes
  h1.main-page-title._margin Список изменений (серверная часть)
  ui-loader(v-if="loader")
  .error-message(v-else-if="error") При загрузке данных произошла ошибка
  .notes(v-else)
    span.description
      | Ниже представлен список изменений серверной части. Вам также доступен список изменений
      |
      router-link.link(:to="{ name: Tabs.ReleaseNotesFront }") внешнего интерфейса.
    template(v-for="v of data" :key="v.number")

      h2 {{ `v${ v.number } (${ v.datePublic })` }}

      h3(v-if="v.changelog.bugfixes") Исправленные ошибки
      ul(v-if="v.changelog.bugfixes")
        li(v-for="(el, index) of v.changelog.bugfixes" :key="'bugfixes' + index") {{ el }}

      h3(v-if="v.changelog.functional") Функциональные улучшения
      ul(v-if="v.changelog.functional")
        li(v-for="(el, index) of v.changelog.functional" :key="'functional' + index") {{ el }}
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useApi } from "@/use/api/useApi";
import { Tabs } from "@/router/tabs";
import UiLoader from "@/components/ui/loader/UiLoader.vue";

interface VersionI {
  number: string,
  datePublic: string,
  changelog: {
    bugfixes?: string[],
    functional?: string[],
  }
}

export default defineComponent({
  name: "ReleaseNotesBack",
  components: {
    UiLoader,
  },
  setup() {

    const data = ref<VersionI[]>([]);

    const loader = ref(true);
    const error = ref(false);

    useApi().releaseNotes.getBackendNotes<VersionI[]>()
      .then((response) => data.value = response)
      .catch(() => error.value = true)
      .finally(() => loader.value = false)

    return {
      data,
      loader,
      error,
      Tabs,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/mixin/links";

#release-notes {
  @include adaptive-view;

  .description {
    font-size: 14px;
    line-height: 18px;

    .link {
      @include link-mixin;
    }
  }

  .notes {
    display: flex;
    flex-flow: column;
  }

  .error-message {
    font-size: 14px;
    line-height: 18px;
    color: var(--main-red-color);
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: var(--default-blue-color);
    margin-top: 32px;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  li {
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    margin: 0;
  }
}
</style>
